<template>
  <div>
    <div class="element-wrapper tags" v-if="!previewDoc">
      <div class="content">
        <div
          v-html="
            renderTags(content, contract, previewDoc, contractPolicies, itemId)
          "
        ></div>
      </div>
      <DocumentElementActions
        :editAction="false"
        :cloneAction="true"
        :deleteAction="true"
        @deleteElement="deleteElement"
        @cloneElement="cloneElement"
        class="actions"
      />
    </div>
    <div v-if="previewDoc" class="content">
      <div
        v-html="
          renderTags(content, contract, previewDoc, contractPolicies, itemId)
        "
      ></div>
    </div>
  </div>
</template>

<script>
import DocumentElementActions from "./DocumentElementActions.vue";
import renderDocBuilderTags from "@/components/documents/mixins/renderDocBuilderTags";

export default {
  name: "DocumentTagElement",
  props: {
    content: String,
    cIndex: Number,
    eIndex: Number,
    itemId: String,
    previewDoc: Boolean,
    contract: Object,
    contractPolicies: Array,
  },
  components: { DocumentElementActions },
  mixins: [renderDocBuilderTags],
  data() {
    return {};
  },
  methods: {
    cloneElement() {
      this.$emit("cloneElement", this.eIndex, this.cIndex);
    },
    deleteElement() {
      this.$emit("deleteElement", this.eIndex, this.cIndex);
    },
  },
};
</script>

<style lang="sass" scoped>
.element-wrapper.tags:hover
  .actions
    display: block
.element-wrapper.tags
  position: relative
  .content
    cursor: grab
    clear: both
    display: block
    float: left
    width: 100%
    margin-bottom: 1px
    padding: 10px
  .actions
    position: absolute
    background: #fff
    font-size: 14px
    padding: 0 5px
    right: 0
    top: 0
    display: none
</style>
