export default {
  data() {
    return {
      companyBankAccounts: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          users: {
            list: [],
            selected: [],
          },
          company: {
            list: [],
            selected: [],
          },
          status: {
            list: [{
              label: "Ativo",
              value: "Ativo",
            }, {
              label: "Desativado",
              value: "Desativado",
            }],
            selected: [],
          },
          period: {
            selected: [],
          },
          companyId: "",
          creditCardId: "",
          searchTerm: "",
          order: "desc",
          orderBy: "created",
        }
      }
    };
  },
  methods: {
    changeCompanyBankAccountsPage(current) {
      this.companyBankAccounts.pagination.page = current;
      this.getcompanyBankAccounts();
    },
    changeCompanyBankAccountsPageSize(current, pageSize) {
      this.companyBankAccounts.pagination.page = current;
      this.companyBankAccounts.pagination.perPage = pageSize;
      this.getCompanyBankAccounts();
    },
    companyBankAccountsTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      this.companyBankAccounts.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.companyBankAccounts.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getCompanyBankAccounts();
    },
    getCompanyBankAccounts(samePage) {

      let filters = "";

      this.companyBankAccounts.loading = true;

      if (this.companyBankAccounts.filters.searchTerm) {
        filters += `&s=${this.companyBankAccounts.filters.searchTerm}`;
      }



      if (this.companyBankAccounts.filters.contractId) {
        filters += `&contract_id=${this.companyBankAccounts.filters.contractId}`;
      }

      if (this.companyBankAccounts.filters.creditCardId) {
        filters += `&credit_card_id=${this.companyBankAccounts.filters.creditCardId}`;
      }

      if (this.companyBankAccounts.filters.operationId) {
        filters += `&credit_card_transaction_id=${this.companyBankAccounts.filters.operationId}`;
      }

      if (this.companyBankAccounts.filters.status.selected.length > 0) {
        filters += `&status=${this.companyBankAccounts.filters.status.selected}`;
      }

      if (this.companyBankAccounts.filters.company.selected.length > 0) {
        filters += `&company_id=${this.companyBankAccounts.filters.company.selected}`;
      }


      if (this.companyBankAccounts.filters.users.selected.length > 0) {
        filters += `&user_id=${this.companyBankAccounts.filters.users.selected}`;
      }

      filters += `&order=${this.companyBankAccounts.filters.order}&order-by=${this.companyBankAccounts.filters.orderBy}`;

      this.$http
        .get(
          `/company-bank-accounts/list?page=${this.companyBankAccounts.pagination.page}&per_page=${this.companyBankAccounts.pagination.perPage}${filters}`
        )
        .then(({
          data
        }) => {
          if (!samePage) {
            this.companyBankAccounts.pagination.page = 1;
          }
          this.companyBankAccounts.pagination.total = data.meta.total;
          this.companyBankAccounts.pagination.totalPages = data.meta.total_pages;
          this.companyBankAccounts.list = data.data;
          this.companyBankAccounts.loading = false;
        })
        .catch(({
          response
        }) => {
          this.companyBankAccounts.loading = false;
          this.companyBankAccounts.list = response.data.data;
          this.companyBankAccounts.pagination.total = response.data.meta.total;
          this.companyBankAccounts.pagination.totalPages = response.data.meta.total_pages;
        });
    },

  }

}